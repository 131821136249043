import React from 'react'
import { useTheme, } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { StaticQuery, graphql } from 'gatsby'
import clsx from 'clsx'
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        top: '-120%',
        width: '100%',        
        zIndex: 4,
        minHeight: '100vh',
        boxShadow: theme.shadows[12],
        opacity: .20,
        mixBlendMode: "luminosity",

        [theme.breakpoints.only('xs')]: {
            top: 0
        }
    }
}))

const IndexBgImage = ({ children, className, ...props }) => {
    const classes = useStyles()
    const theme = useTheme()

    return (        
        <StaticQuery
            query={graphql`
                query {
                    file(relativePath: { eq: "bg.jpg" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }                    
                }
            `}
            render={data => {
                return <Img fluid={data.file.childImageSharp.fluid} className={clsx(classes.root, className)} style={{position: "absolute"}} />
            }}
        />
    )
}

export default IndexBgImage